import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CmsAuthGuard} from '@next/next.users.auth.cms';
import {NotFoundPageComponent} from './components/core/not-found-page/not-found-page.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'practices',
    pathMatch: 'full'
  },
  {
    path: 'practices',
    loadChildren: () => import('./modules/practices/practices.module').then(m => m.PracticesModule),
    data: {
      breadcrumb: 'practices.my-practices.name'
    },
    canActivate: [CmsAuthGuard]
  },
  {
    path: 'censimento-ticsi',
    redirectTo: 'practices/add-census-practice',
    pathMatch: 'full'
  },
  {
    path: 'error/not-found',
    component: NotFoundPageComponent,
    data: {
      breadcrumb: 'error-page.404.title',
    },
    canActivate: [CmsAuthGuard]
  },
  {
    path: '**',
    redirectTo: 'error/not-found'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
