import {Component} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {BreadcrumbDefinition, BreadcrumbService} from 'xng-breadcrumb';


@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {
  goBack: boolean;
  baseUri: string;
  breadcrumbs: Array<BreadcrumbDefinition>;

  constructor(private breadcrumbService: BreadcrumbService) {
    this.breadcrumbService.breadcrumbs$.subscribe(crumbs => {
      this.goBack = crumbs.some(c => c.label === 'go-back');
      this.breadcrumbs = crumbs;
    });

    this.baseUri = `${environment.protocol}://${environment.apiBaseUrl}/`;
  }

}
