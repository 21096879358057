import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SharedModule} from './modules/shared/shared.module';
import {environment} from '../environments/environment';
import {CmsAuthLibModule} from '@next/next.users.auth.cms';
import {NotFoundPageComponent} from './components/core/not-found-page/not-found-page.component';
import {registerLocaleData} from '@angular/common';
import localeIt from '@angular/common/locales/it';

registerLocaleData(localeIt);

@NgModule({
  declarations: [
    AppComponent,
    NotFoundPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,

    CmsAuthLibModule.forRoot({
      protocol: environment.protocol,
      apiBaseUrl: environment.apiBaseUrl,
      ...environment.auth
    })
  ],
  providers: [{
    provide: LOCALE_ID,
    useValue: environment.defaultLanguage
  }],
  bootstrap: [AppComponent]
})
export class AppModule {
}
