import {Component, OnDestroy, OnInit} from '@angular/core';
import {of, Subscription} from 'rxjs';
import {NotificationService} from '../../../store/notifications/services/notification.service';
import {INotifications} from '../../../interfaces/notification';
import {concatMap, delay} from 'rxjs/operators';

declare let notificationShow: any; // declare notificationShow function of bootstrap italia js

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy {
  notificationSubscription: Subscription;
  notifications: INotifications = {
    success: undefined,
    error: undefined,
    warning: undefined,
    info: undefined,
  };

  constructor(
    private notificationService: NotificationService
  ) {
  }

  ngOnInit(): void {
    this.notificationSubscription = this.notificationService.onNotification().pipe(
      concatMap(notification => { // Wait for the last notification with the same type to finish
          const event = of(notification);
          return (this.notifications[notification.type] !== undefined) ?
            event.pipe(delay(this.notifications[notification.type].duration))
            : event;
        }
      )
    ).subscribe(notification => {
      if (!notification.duration) {
        notification.duration = 8000; // Add duration if not is set
      }

      this.notifications[notification.type] = notification;
      setTimeout(() => {
        this.notifications[notification.type] = undefined; // Clear notification after the duration
      }, notification.duration);

      // Show the notification
      notificationShow(`${notification.type}-notification`, notification.duration);
    });
  }

  ngOnDestroy(): void {
    this.notificationSubscription.unsubscribe();
  }
}
