import {AfterViewInit, Component, forwardRef, Input} from '@angular/core';
import {AbstractControl, ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => FormInputComponent)
  }]
})
export class FormInputComponent implements AfterViewInit, ControlValueAccessor {

  /**
   * The form group
   */
  @Input() formControlGroup: AbstractControl;

  /**
   * The complete control name
   * example: 'Delegate.Properties.RealEstateCity'
   */
  @Input() controlFullName: string;

  /**
   * Id of input
   */
  @Input() id: string = undefined;

  /**
   * Type of input ['text', 'number', 'email', 'date']
   */
  @Input() type = 'text';

  /**
   * The more class
   */
  @Input() class = '';

  /**
   * i18n of placeholder, the translate pipe is added automatically
   * example: 'forms.placeholders.firstname'
   */
  @Input() placeholder: string = undefined;

  value = new FormControl();
  touched = false;

  onChange: any = () => {
    return 0;
  }
  onTouch: any = () => {
    return 0;
  }

  ngAfterViewInit(): void {
    this.value.valueChanges.subscribe(val => {
      this.onChange(val);
    });
  }

  writeValue(obj: any): void {
    this.value.setValue(obj);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.value.disable();
      return;
    }

    this.value.enable();
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouch();
      this.touched = true;
    }
  }

  isInvalid(): boolean {
    if (!this.controlFullName) {
      return false;
    }
    return this.formControlGroup.get(this.controlFullName).invalid && this.formControlGroup.get(this.controlFullName).touched;
  }

  isValid(): boolean {
    if (!this.controlFullName) {
      return true;
    }
    return this.formControlGroup.get(this.controlFullName).valid;
  }
}
