<div class="notification with-icon success {{notifications.success?.position}} {{notifications.success?.dismissable ? 'dismissable' : ''}}" role="alert" aria-labelledby="success-notification-title" id="success-notification">
  <h5 id="success-notification-title">
    <svg class="icon">
      <use xlink:href="/bootstrap-italia/dist/svg/sprite.svg#it-check-circle"></use>
    </svg>
    <ng-container *ngIf="notifications.success">{{notifications.success.title}} </ng-container>
  </h5>
  <p *ngIf="notifications.success && notifications.success.message">{{notifications.success.message}}</p>
</div>

<div class="notification with-icon error {{notifications.error?.position}} {{notifications.error?.dismissable ? 'dismissable' : ''}}" role="alert" aria-labelledby="error-notification-title" id="error-notification">
  <h5 id="error-notification-title">
    <svg class="icon">
      <use xlink:href="/bootstrap-italia/dist/svg/sprite.svg#it-close-circle"></use>
    </svg>
    <ng-container *ngIf="notifications.error">{{notifications.error.title}} </ng-container>
  </h5>
  <p *ngIf="notifications.error && notifications.error.message">{{notifications.error.message}}</p>
</div>

<div class="notification with-icon info {{notifications.info?.position}} {{notifications.info?.dismissable ? 'dismissable' : ''}}" role="alert" aria-labelledby="info-notification-title" id="info-notification">
  <h5 id="info-notification-title">
    <svg class="icon">
      <use xlink:href="/bootstrap-italia/dist/svg/sprite.svg#it-info-circle"></use>
    </svg>
    <ng-container *ngIf="notifications.info">{{notifications.info.title}} </ng-container>
  </h5>
  <p *ngIf="notifications.info && notifications.info.message">{{notifications.info.message}}</p>
</div>

<div class="notification with-icon warning {{notifications.warning?.position}} {{notifications.warning?.dismissable ? 'dismissable' : ''}}" role="alert" aria-labelledby="warning-notification-title" id="warning-notification">
  <h5 id="warning-notification-title">
    <svg class="icon">
      <use xlink:href="/bootstrap-italia/dist/svg/sprite.svg#it-error"></use>
    </svg>
    <ng-container *ngIf="notifications.warning">{{notifications.warning.title}} </ng-container>
  </h5>
  <p *ngIf="notifications.warning && notifications.warning.message">{{notifications.warning.message}}</p>
</div>
