<nav class="pagination-wrapper justify-content-center">
  <ul class="pagination">
    <li class="page-item" [class.disabled]="currentPage < 1">
      <a class="page-link" (click)="pageChange(currentPage)" tabindex="-1" aria-hidden="true">
        <svg class="icon icon-primary">
          <use xlink:href="/bootstrap-italia/dist/svg/sprite.svg#it-chevron-left"></use>
        </svg>
        <span class="sr-only">{{ 'pagination.previous-page' | translate}}</span>
      </a>
    </li>

    <ng-container *ngIf="pageNumbers > 5 && currentPage > 2">
      <li class="page-item"><a class="page-link" (click)="pageChange(1)">1</a></li>
      <li class="page-item" *ngIf="currentPage > 3">
        <span class="page-link">...</span>
      </li>
    </ng-container>

    <li class="page-item" *ngFor="let page of pages">
      <a class="page-link" href="#" aria-current="page" *ngIf="page === (currentPage + 1); else inactivePage">
        <span
          class="d-inline-block d-sm-none">{{ 'pagination.page' | translate}} </span> {{page}}
      </a>
      <ng-template #inactivePage>
        <a class="page-link" (click)="pageChange(page)">{{page}}</a>
      </ng-template>
    </li>

    <ng-container *ngIf="pageNumbers > 5 && pageNumbers - currentPage > 3">
      <li class="page-item">
        <span class="page-link">...</span>
      </li>
      <li class="page-item"><a class="page-link" (click)="pageChange(pageNumbers)">{{pageNumbers}}</a></li>
    </ng-container>

    <li class="page-item" [class.disabled]="currentPage >= pageNumbers - 1">
      <a class="page-link" (click)="pageChange(currentPage + 2)" tabindex="-1" aria-hidden="true">
        <span class="sr-only">{{ 'pagination.next-page' | translate}}</span>
        <svg class="icon icon-primary">
          <use xlink:href="/bootstrap-italia/dist/svg/sprite.svg#it-chevron-right"></use>
        </svg>
      </a>
    </li>
  </ul>
</nav>
