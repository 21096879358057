<!--Loader-->
<div class="text-center mt-4" *ngIf="isLoading; else resultTemplate">
  <app-progress-spinner></app-progress-spinner>
</div>

<ng-template #resultTemplate>
  <lib-cms-auth-header></lib-cms-auth-header>
  <main id="BodyCorpo">
    <app-breadcrumb></app-breadcrumb>
    <section id="sectioncontent">
      <div class="row">
        <section id="ColSX" class="hidden-xs "></section>
        <section id="ColCX" class="col-md-12">
          <div id="ctl35">
            <!--ZOOMRESTART-->
            <div class="frontend-notify disabled"></div>
            <div class="wrapper">
              <div class="container mb-4">
                <div class="row">
                  <div class="col-md-12">
                    <router-outlet></router-outlet>
                    <app-notifications></app-notifications>
                  </div>
                </div>
              </div>
            </div>
            <!--ZOOMSTOP-->
          </div>
        </section>
        <section id="ColDX"></section>
      </div>
      <section id="PiePagina" class="container-fluid"></section>
    </section>
  </main>
  <lib-cms-auth-footer></lib-cms-auth-footer>
</ng-template>
