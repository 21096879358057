<input [id]="id"
       [type]="type"
       class="form-control {{class}}"
       [class.is-invalid]="isInvalid()"
       [class.is-valid]="isValid()"
       [formControl]="value"
       (blur)="markAsTouched()"
/>
<label *ngIf="placeholder && type!=='date'" [for]="id">{{placeholder | translate}}</label>
<div *ngIf="isInvalid()" class="invalid-feedback">
  <ng-container *ngIf="type !== 'email'; else isEmail">{{'forms.required-message' | translate}}</ng-container>
  <ng-template #isEmail>{{'forms.required-email-message' | translate}}</ng-template>
</div>
