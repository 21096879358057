import {Component, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {Location} from '@angular/common';

@Component({
  selector: 'app-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss']
})
export class NotFoundPageComponent implements OnInit {

  homepageLink: string;

  constructor(
    private location: Location
  ) {
  }

  ngOnInit(): void {
    this.homepageLink = `${environment.protocol}://${environment.apiBaseUrl}`;
  }

  goBack(): void {
    this.location.back();
  }
}
