import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {environment} from '../../../environments/environment';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {BreadcrumbComponent} from '../../components/core/breadcrumb/breadcrumb.component';
import {RouterModule} from '@angular/router';
import {NotificationsComponent} from '../../components/shared/notifications/notifications.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LoadingButtonComponent} from '../../components/shared/loading-button/loading-button.component';
import {ProgressSpinnerComponent} from '../../components/shared/progress-spinner/progress-spinner.component';
import {TablePaginationComponent} from '../../components/shared/table-pagination/table-pagination.component';
import {FormInputComponent} from '../../components/shared/forms/form-input/form-input.component';
import {FormSelectComponent} from '../../components/shared/forms/form-select/form-select.component';
import {BreadcrumbModule} from 'xng-breadcrumb';

export function httpTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    BreadcrumbComponent,
    NotificationsComponent,
    FormInputComponent,
    FormSelectComponent,
    LoadingButtonComponent,
    ProgressSpinnerComponent,
    TablePaginationComponent
  ],
  imports: [
    CommonModule,
    BreadcrumbModule,
    FormsModule,
    ReactiveFormsModule,

    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      },
      defaultLanguage: environment.defaultLanguage
    }),
    RouterModule
  ],
  exports: [
    TranslateModule,
    BreadcrumbComponent,
    NotificationsComponent,
    LoadingButtonComponent,
    FormInputComponent,
    FormSelectComponent,
    TablePaginationComponent,
    ProgressSpinnerComponent,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class SharedModule {
}
