<div class="bootstrap-select-wrapper">
  <label>{{label | translate}}</label>
  <div class="dropdown bootstrap-select">
    <button type="button" tabindex="-1" class="btn dropdown-toggle btn-light bs-placeholder" data-toggle="dropdown">
      <span class="filter-option">
        <span class="filter-option-inner">
          <span class="filter-option-inner-inner">{{selectedValue | translate}}</span>
        </span>
      </span>
    </button>
    <div class="dropdown-menu">
      <div class="inner show" role="listbox">
        <ul class="dropdown-menu inner show" role="presentation">
          <li *ngFor="let option of options | keyvalue: originalOrder" (click)="selectValue(option.key)">
            <a role="option" class="dropdown-item"><span class="text">{{option.value | translate}}</span></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
