<div class="error-template text-center mt-5">
  <h1>404</h1>
  <h2>{{'error-page.404.title' | translate}}</h2>
  <p>{{'error-page.404.description' | translate}}
    <button (click)="goBack()" class="btn btn-link"
            title="{{'error-page.404.go-back' | translate}}">{{'error-page.404.go-back' | translate}}</button>
  </p>
  <a class="btn btn-primary mb-5" href="{{homepageLink}}"
     title="{{'error-page.404.go-to-homepage' | translate}}">{{'error-page.404.go-to-homepage' | translate}}</a>
</div>
