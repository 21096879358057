import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {CmsAuthLibSelector} from '@next/next.users.auth.cms';
import {BreadcrumbService} from 'xng-breadcrumb';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isLoading: boolean;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private readonly store$: Store
  ) {
  }

  ngOnInit(): void {
    this.store$.select(CmsAuthLibSelector.selectIsReadyFront).subscribe(
      isReady => this.isLoading = !isReady
    );
  }
}
