import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {KeyValue} from '@angular/common';

@Component({
  selector: 'app-form-select',
  templateUrl: './form-select.component.html',
  styleUrls: ['./form-select.component.scss']
})
export class FormSelectComponent implements OnInit {

  /**
   * The options of select Map<key, value> (value is the showed text)
   * value can be an i18n element. example: Map<"Other", 'practices.documents.document-types.other-document'>
   */
  @Input() options: Map<string, string>;

  /**
   * The label to show on select
   */
  @Input() label = '';

  /**
   * The selected value; default first element
   */
  selectedValue: string;

  /**
   * The selected key; default first element
   */
  selectedKey: string;

  /**
   * EventEmitter called when selection change
   */
    // tslint:disable-next-line:no-output-native
  @Output() change: EventEmitter<{ key: string, value: string }> = new EventEmitter<{ key: string, value: string }>();

  ngOnInit(): void {
    this.selectedKey = this.options.keys().next().value;
    this.selectedValue = this.options.get(this.selectedKey);
  }

  selectValue(key: string): void {
    this.selectedKey = key;
    this.selectedValue = this.options.get(key);
    this.change.emit({key, value: this.selectedValue});
  }

  /**
   * Preserve original property order
   */
  originalOrder = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
    return 0;
  }
}
