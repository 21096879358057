export interface INotification {
  type: NotificationType;
  title: string;
  message?: string;
  duration?: number;
  dismissable?: boolean;
  position?: NotificationPosition;
}

export interface INotifications {
  success?: INotification;
  error?: INotification;
  warning?: INotification;
  info?: INotification;
}

export enum NotificationType {
  Success = 'success',
  Error = 'error',
  Info = 'info',
  Warning = 'warning'
}

export enum NotificationPosition {
  Top = 'top-fix mt-3',
  Bottom = 'bottom-fix mb-3',
  Left = 'left-fix',
  Right = 'right-fix'
}
