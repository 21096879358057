import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-loading-button',
  templateUrl: './loading-button.component.html',
  styleUrls: ['./loading-button.component.scss']
})
export class LoadingButtonComponent {

  /**
   * Condition to enable the loader
   */
  @Input() isLoading: boolean;

  /**
   * Condition to disable the button
   */
  @Input() isDisabled: boolean;

  /**
   * Text of button
   */
  @Input() buttonText: string;

  /**
   * Button color and class
   * Es: 'primary', 'outline-primary mt-2'
   */
  @Input() buttonColor = 'primary';

  /**
   * Type of button
   * Es: 'button', 'submit'
   */
  @Input() buttonType = 'button';

}
