import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {INotification, NotificationPosition, NotificationType} from '../../../interfaces/notification';
import {filter} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private subject = new Subject<INotification>();

  /**
   * Listen on notification arrived
   * @param filterType filter type of notification
   */
  onNotification(filterType?: NotificationType): Observable<INotification> {
    return this.subject.asObservable().pipe(
      filter(n => n && (!filterType || (n.type === filterType)))
    );
  }

  /**
   * Send notification
   * @param notification notification
   */
  addNotification(notification: INotification): void {
    this.subject.next(notification);
  }

  /**
   * Create new Success notification
   * @param title notification title
   * @param message notification message
   * @param duration notification duration (millis)
   * @param position notification position
   */
  success(title: string, message?: string, duration?: number, position?: NotificationPosition): void {
    this.addNotification({
      type: NotificationType.Success,
      message,
      title,
      duration,
      position
    });
  }

  /**
   * Create new Error notification
   * @param title notification title
   * @param message notification message
   * @param duration notification duration (millis)
   * @param position notification position
   */
  error(title: string, message?: string, duration?: number, position?: NotificationPosition): void {
    this.addNotification({
      type: NotificationType.Error,
      message,
      title,
      duration,
      position
    });
  }

  /**
   * Create new Warning notification
   * @param title notification title
   * @param message notification message
   * @param duration notification duration (millis)
   * @param position notification position
   */
  warning(title: string, message?: string, duration?: number, position?: NotificationPosition): void {
    this.addNotification({
      type: NotificationType.Warning,
      message,
      title,
      duration,
      position
    });
  }

  /**
   * Create new Info notification
   * @param title notification title
   * @param message notification message
   * @param duration notification duration (millis)
   * @param position notification position
   */
  info(title: string, message?: string, duration?: number, position?: NotificationPosition): void {
    this.addNotification({
      type: NotificationType.Info,
      message,
      title,
      duration,
      position
    });
  }
}
