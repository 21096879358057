<div class="container">
  <nav id="Where" class="breadcrumb-container" aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li *ngIf="goBack; else breadcrumbContent">
        <a href="#" class="go-back mb-5">
          <svg class="icon icon-sm icon-primary mr-2">
            <use xlink:href="/bootstrap-italia/dist/svg/sprite.svg#it-arrow-left"></use>
          </svg>
          {{'breadcrumbs.go-back' | translate}}
        </a>
      </li>
      <ng-template #breadcrumbContent>
        <li><a href="{{this.baseUri}}">{{'breadcrumbs.home' | translate}}</a></li>
        <li><a href="{{this.baseUri}}area-info-servizi/">{{'breadcrumbs.service-area' | translate}}</a></li>
        <ng-template ngFor let-route let-i="index + 1" [ngForOf]="breadcrumbs">
          <li *ngIf="i !== breadcrumbs.length">
            <a href="" [routerLink]="[route.routeLink]">{{route.label | translate}}</a>
          </li>
          <li *ngIf="i === breadcrumbs.length" class="active" aria-current="page">{{route.label | translate}}</li>
        </ng-template>
      </ng-template>
    </ol>
  </nav>
</div>
