// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  defaultLanguage: 'it',

  protocol: 'https',
  apiBaseUrl: 'sidraspa.net-serv.it',

  auth: {
    rootAuth: 'api/auth',

    clientId: 'b0ff58f48f377ef69c5abfea745654372ec32013.pratiche_sidra_spa',
    clientSecret: 'e9420733-e79c-b647-aa51-f6f5f661cbf3',
    grantType: 'client_credentials',

    cookieName: 'sharedCookie',
    loginUrl: 'https://sidraspa.net-serv.it/user/accesso.aspx?redirectPractices=1'
  },

  censusCryptoKey: 'KaNdRgUkXp2s5v8y',
  censusCryptoIv: 'KaNdRgUkXp2s5v8y'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
